<style scoped>
</style>
<template>
  <CSidebar :minimize="minimize" unfoldable :show.sync="show" class="sideBar">
    <!-- <CSidebarHeader/> -->
    <CSidebarBrand :wrappedInLink="{ href: '/', target: '_blank' }">
      <CIcon
        class="c-sidebar-brand-full"
        :name="srcLarge.alt"
        size="custom-size"
        :height="srcLarge.height"
        :src="srcLarge.src"
        viewBox="0 0 556 134"
      />
      <CIcon
        class="c-sidebar-brand-minimized"
        :name="srcSquare.alt"
        size="custom-size"
        :height="srcSquare.height"
        :src="srcSquare.src"
        viewBox="0 0 110 134"
      />
    </CSidebarBrand>

    <!-- <CSidebarNav/> -->
    <CSidebarNav>
      <CSidebarNavTitle>Actions</CSidebarNavTitle>
      <CSidebarNavItem :name="$t('drivers')" to="/dashboard" icon="cil-people" />
      <!-- <CSidebarNavItem
        :name="$t('console_user')"
        to="/users"
        icon="cil-people"
        v-if="isSuperadmin || isCountryAdmin"
      /> -->
      <CSidebarNavItem
        :name="$t('version_control')"
        to="/versions"
        icon="cil-mobile"
        v-if="isSuperadmin"
      />
      <CSidebarNavItem
        :name="$t('country_settings')"
        to="/settings"
        icon="cil-settings"
        v-if="isSuperadmin || isCountryAdmin"
      />
      <!-- <CSidebarNavItem 
        :name="$t('analytics')" 
        to="/analytics" 
        icon="cil-chart-pie" 
        v-if="!isCarrierAdmin"
      /> -->
      <CSidebarNavItem 
        :name="$t('surveys')" 
        to="/surveys" 
        icon="cil-speech" 
        v-if="!isCarrierAdmin"
      />
      <CSidebarNavItem
        :name="$t('shiptos')"
        to="/shipTos"
        icon="cil-notes"
        v-if="isSuperadmin || isShipToManager || isCountryAdmin"
      />
      <CSidebarNavItem
        :name="$t('safety_check')"
        to="/safetyChecks"
        icon="cil-notes"
        v-if="isSuperadmin || isCountryAdmin"
      />
      <CSidebarNavItem
        :name="$t('completed')"
        to="/completedAtWork"
        icon="cil-task"
        v-if="isSuperadmin || isShipToManager || isCountryAdmin"
      />
      <CSidebarNavItem 
        :name="$t('unfinished_load_lines')" 
        to="/unfinisheddeliveries" 
        icon="cil-calendar" 
        v-if="!isCarrierAdmin"
      />
    </CSidebarNav>

    <!-- <CSidebarFooter/> -->
    <!-- <CSidebarMinimizer
      class="c-d-md-down-none"
      @click.native="minimize = !minimize"
    /> -->
    <CSidebarNavItem
      :name= "$t('logout')"
      to="/login"
      onClick="localStorage.removeItem('user')"
      icon="cil-account-logout"
    />
  </CSidebar>
</template>

<script>
import types from "./../ThemeJs/theme.js";
import { bus } from "@/main.js";

export default {
  name: "TheSidebar",
  data() {
    return {
      rol: "", //sessionStorage.getItem('userRol'),
      isSuperadmin: Boolean,
      isCountryAdmin: Boolean,
      isCarrierAdmin: Boolean,
      minimize: true,
      // nav: JSON.parse(sessionStorage.getItem("user")).menu1,
      show: true,
      srcLarge: { height: 46, alt: "Logo", src: "" },
      srcSquare: { height: 46, alt: "Logo", src: "" },
    };
  },
  async created() {
    this.rol = JSON.parse(localStorage.getItem("userRol"));

    if (this.rol == "superadmin") this.isSuperadmin = true;
    else this.isSuperadmin = false;

    if (this.rol == "countryAdmin") this.isCountryAdmin = true;
    else this.isCountryAdmin = false;

    if (this.rol == "shipToManager") this.isShipToManager = true;
    else this.isShipToManager = false;

    if (this.rol == "carrierAdmin") this.isCarrierAdmin = true;
    else this.isCarrierAdmin = false;
  },
  mounted() {
    // console.log(types.types.theme);
    var temas = types.types.theme;

    temas.map((tema, i) => {
      if (
        tema.state &&
        document.querySelector("body").classList.contains("c-dark-theme")
      ) {
        if (tema.name == "holcim") {
          this.srcLarge.src = tema.logoLargeDark;
          this.srcSquare.src = tema.logoSquareDark;
        }
        if (tema.name == "laFarge") {
          this.srcLarge.src = tema.logoLargeDark;
          this.srcSquare.src = tema.logoSquareDark;
        }
        if (tema.name == "laFargeHolcim") {
          this.srcLarge.src = tema.logoLargeDark;
          this.srcSquare.src = tema.logoSquareDark;
        }
        if (tema.name == "holcimRed") {
          this.srcLarge.src = tema.logoLargeDark;
          this.srcSquare.src = tema.logoSquareDark;
        }
        if (tema.name == "laFargeGreen") {
          this.srcLarge.src = tema.logoLargeDark;
          this.srcSquare.src = tema.logoSquareDark;
        }
        if (tema.name == "laFargeHolcimBlue") {
          this.srcLarge.src = tema.logoLargeDark;
          this.srcSquare.src = tema.logoSquareDark;
        }
        if (tema.name == "holcimRedAlternative") {
          this.srcLarge.src = tema.logoLargeDark;
          this.srcSquare.src = tema.logoSquareDark;
        }
        if (tema.name == "laFargeGreenAlternative") {
          this.srcLarge.src = tema.logoLargeDark;
          this.srcSquare.src = tema.logoSquareDark;
        }
        if (tema.name == "laFargeHolcimblueAlternative") {
          this.srcLarge.src = tema.logoLargeDark;
          this.srcSquare.src = tema.logoSquareDark;
        }
      } else if (tema.state) {
        if (tema.name == "holcim") {
          //  console.log(tema.name)
          this.srcLarge.src = tema.logoLarge;
          this.srcSquare.src = tema.logoSquare;
        }
        if (tema.name == "laFarge") {
          // console.log(tema.name)
          this.srcLarge.src = tema.logoLarge;
          this.srcSquare.src = tema.logoSquare;
        }
        if (tema.name == "laFargeHolcim") {
          // console.log(tema.name)
          this.srcLarge.src = tema.logoLarge;
          this.srcSquare.src = tema.logoSquare;
        }
        if (tema.name == "holcimRed") {
          // console.log(tema.name)
          this.srcLarge.src = tema.logoLarge;
          this.srcSquare.src = tema.logoSquare;
        }
        if (tema.name == "laFargeGreen") {
          // console.log(tema.name)
          this.srcLarge.src = tema.logoLarge;
          this.srcSquare.src = tema.logoSquare;
        }
        if (tema.name == "laFargeHolcimBlue") {
          // console.log(tema.name)
          this.srcLarge.src = tema.logoLarge;
          this.srcSquare.src = tema.logoSquare;
        }
        if (tema.name == "holcimRedAlternative") {
          // console.log(tema.name)
          this.srcLarge.src = tema.logoLarge;
          this.srcSquare.src = tema.logoSquare;
        }
        if (tema.name == "laFargeGreenAlternative") {
          // console.log(tema.name)
          this.srcLarge.src = tema.logoLarge;
          this.srcSquare.src = tema.logoSquare;
        }
        if (tema.name == "laFargeHolcimblueAlternative") {
          // console.log(tema.name)
          this.srcLarge.src = tema.logoLarge;
          this.srcSquare.src = tema.logoSquare;
        }
      }
    });

    bus.$on("tema", (tema1) => {
      // console.log(tema1)
      if (
        tema1.state &&
        document.querySelector("body").classList.contains("c-dark-theme")
      ) {
        if (tema1.name == "holcim") {
          this.srcLarge.src = tema1.logoLargeDark;
          this.srcSquare.src = tema1.logoSquareDark;
        }
        if (tema1.name == "laFarge") {
          this.srcLarge.src = tema1.logoLargeDark;
          this.srcSquare.src = tema1.logoSquareDark;
        }
        if (tema1.name == "laFargeHolcim") {
          this.srcLarge.src = tema1.logoLargeDark;
          this.srcSquare.src = tema1.logoSquareDark;
        }
        if (tema1.name == "holcimRed") {
          this.srcLarge.src = tema1.logoLargeDark;
          this.srcSquare.src = tema1.logoSquareDark;
        }
        if (tema1.name == "laFargeGreen") {
          this.srcLarge.src = tema1.logoLargeDark;
          this.srcSquare.src = tema1.logoSquareDark;
        }
        if (tema1.name == "laFargeHolcimBlue") {
          this.srcLarge.src = tema1.logoLargeDark;
          this.srcSquare.src = tema1.logoSquareDark;
        }
        if (tema1.name == "holcimRedAlternative") {
          this.srcLarge.src = tema1.logoLargeDark;
          this.srcSquare.src = tema1.logoSquareDark;
        }
        if (tema1.name == "laFargeGreenAlternative") {
          this.srcLarge.src = tema1.logoLargeDark;
          this.srcSquare.src = tema1.logoSquareDark;
        }
        if (tema1.name == "laFargeHolcimblueAlternative") {
          this.srcLarge.src = tema1.logoLargeDark;
          this.srcSquare.src = tema1.logoSquareDark;
        }
      } else if (tema1.state == true) {
        if (tema1.name == "holcim") {
          //  console.log(tema1.name)
          this.srcLarge.src = tema1.logoLarge;
          this.srcSquare.src = tema1.logoSquare;
        }
        if (tema1.name == "laFarge") {
          // console.log(tema1.name)
          this.srcLarge.src = tema1.logoLarge;
          this.srcSquare.src = tema1.logoSquare;
        }
        if (tema1.name == "laFargeHolcim") {
          // console.log(tema1.name)
          this.srcLarge.src = tema1.logoLarge;
          this.srcSquare.src = tema1.logoSquare;
        }
        if (tema1.name == "holcimRed") {
          // console.log(tema1.name)
          this.srcLarge.src = tema1.logoLarge;
          this.srcSquare.src = tema1.logoSquare;
        }
        if (tema1.name == "laFargeGreen") {
          // console.log(tema1.name)
          this.srcLarge.src = tema1.logoLarge;
          this.srcSquare.src = tema1.logoSquare;
        }
        if (tema1.name == "laFargeHolcimBlue") {
          // console.log(tema1.name)
          this.srcLarge.src = tema1.logoLarge;
          this.srcSquare.src = tema1.logoSquare;
        }
        if (tema1.name == "holcimRedAlternative") {
          // console.log(tema1.name)
          this.srcLarge.src = tema1.logoLarge;
          this.srcSquare.src = tema1.logoSquare;
        }
        if (tema1.name == "laFargeGreenAlternative") {
          // console.log(tema1.name)
          this.srcLarge.src = tema1.logoLarge;
          this.srcSquare.src = tema1.logoSquare;
        }
        if (tema1.name == "laFargeHolcimblueAlternative") {
          // console.log(tema1.name)
          this.srcLarge.src = tema1.logoLarge;
          this.srcSquare.src = tema1.logoSquare;
        }
      }
    });

    this.$root.$on("toggle-sidebar", () => {
      const sidebarOpened = this.show === true || this.show === "responsive";
      this.show = sidebarOpened ? false : "responsive";
    });

    this.$root.$on("toggle-sidebar-mobile", () => {
      const sidebarClosed = this.show === "responsive" || this.show === false;
      this.show = sidebarClosed ? true : "responsive";
    });
  },
  methods: {
    viewOnSideBarDefault(e) {
      document.addEventListener("click", (e) => {
        var eventTarget = e.target.classList;
        if (
          eventTarget.contains("c-sidebar-nav-link") ||
          eventTarget.contains("c-sidebar-nav-dropdown-toggle") ||
          eventTarget.contains("c-sidebar-nav") ||
          eventTarget.contains("c-sidebar-nav-icon")
        ) {
          this.minimize = false;
        } else if (eventTarget.contains("c-sidebar-minimizer") == false) {
          this.minimize = true;
        }
      });
    },
  },
};
</script>
