<template>
  <CFooter>
    <div>
      <a class="footer-links" href="#">{{$t('rcs_cloud')}}</a>
      <span class="ml-1">&copy; 2020.</span>
    </div>
    <div class="ml-auto">
    </div>
    <div class="ml-auto">
      {{ username }} | v{{ version }} | {{ userCountry ? userCountry : $t('all') }} | {{ rol }} | {{ enviroment }} |
      <span class="mr-1" target="_blank">{{$t('powered_by')}}</span>
      <a class="footer-links" href="https://www.amurait.com">{{$t('amura_it')}}</a>
    </div>
  </CFooter>
</template>

<script>
import Vue from "vue"
import { getEnvironment, getVersion } from '../main';
export default {
  name: 'TheFooter',
  data() {
    return {
      enviroment : '',
      rol: '',
      userCountry: '',
      username: '',
      version: '',

    }
  },
  async mounted() {

    let response = await getEnvironment()
    this.enviroment = response.data;
    let user = JSON.parse(localStorage.getItem("user"));
    this.rol = user.rol
    this.userCountry = user.country
    this.username = user.username
    this.version = Vue.prototype.$version
    
  }
}
</script>
